/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


        $(document).ready(function() {

             var arr = $('.gallery-image').map(function() {
                return $(this);
              }).toArray();
             var modal = $('#photoModal');

            $('#photoModal').on('show.bs.modal', function (event) {
                var button = $(event.relatedTarget);
                if (button.hasClass('carousel-image')) {
                  var image = button.attr("src");
                  $('.modal-image').attr("src", image);
                } else {
                  var imageTwo = button.data("image");
                  var title = button.data("title");
                  modal.find('.aggregate-name').remove();
                  modal.find('.modal-content').append('<div class="aggregate-name"><h3>' + title + '</h3></div>');
                  $('.modal-image').attr("src", imageTwo);
                }



                count = -1;
                for(var i = 0, len = arr.length; i < len; i++) {
                  count += 1;
                  if($('.modal-image').attr("src") === arr[i].attr('data-image')) {
                    break;
                  }
                }
            });

            var image = $('gallery-image').data('image');

            var carousel = $('.carousel');
            $('.carousel-image').attr("src", arr[0].data("image"));

            count = -1;
            for(var i = 0, len = arr.length; i < len; i++) {
              count += 1;
              if($('.carousel-image').attr("src") === arr[i].attr('data-image')) {
                break;
              }
            }

            $('.gallery-image').on("click", function() {
                var this_image = $(this).data('image');
                $('.carousel-image').attr("src", this_image);
                count = -1;
                for(var i = 0, len = arr.length; i < len; i++) {
                  count += 1;
                  if($('.carousel-image').attr("src") === arr[i].attr('data-image')) {
                    break;
                  }
                }
            });

            $('.carousel-dialog #next').on("click", function() {
              if(count < (arr.length - 1)) {
                count += 1;
              }
              $('.carousel-image').attr("src", arr[count].attr('data-image'));
            });

            $('.carousel-dialog #prev').on("click", function() {
              if (count > 0) {
                count -= 1;
              }
              $('.carousel-image').attr("src", arr[count].attr('data-image'));
            });

             $('.modal-dialog #next').on("click", function() {
              if(count < (arr.length - 1)) {
                count += 1;
              }
              $('.carousel-image').attr("src", arr[count].attr('data-image'));
              $('.modal-image').attr("src", arr[count].attr('data-image'));
              modal.find('.aggregate-name h3').text(arr[count].attr('data-title'));

            });

            $('.modal-dialog #prev').on("click", function() {
              if (count > 0) {
                count -= 1;
              }
              $('.carousel-image').attr("src", arr[count].attr('data-image'));
              $('.modal-image').attr("src", arr[count].attr('data-image'));
              modal.find('.aggregate-name h3').text(arr[count].attr('data-title'));
            });
          });


        },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


 function initialize_gmap(map_lat, map_long, point_lat, point_long, address, title) {

              var latlng = new google.maps.LatLng(map_lat, map_long);

              var settings = {
                zoom: 15,
                center: latlng,
                scrollwheel: false,
                draggable: false,
                mapTypeControl: true,
                mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
                navigationControl: true,
                navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
                mapTypeId: google.maps.MapTypeId.ROADMAP};

              var map = new google.maps.Map(document.getElementById("map_canvas"), settings);
              var contentString = '<div id="content" style="text-align:left;">'+
                '<div id="siteNotice">'+
                '</div>'+
                '<a href="http://goo.gl/E434pu" target="_blank"><h4>' + title + '</h4></a>'+
                '<div id="bodyContent">'+
                '<p>' + address + '</p>'+
                '</div>'+
                '</div>';
              var infowindow = new google.maps.InfoWindow({
                content: contentString
              });

              var companyMarker = new google.maps.Marker({
                position: latlng,
                map: map,

                title:"M.E. Astbury & Son"});

              google.maps.event.addListener(companyMarker, 'click', function() {
                infowindow.open(map,companyMarker);
              });
            }
